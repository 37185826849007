import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import Box from "react-raster"
import { Heading, Link, SectionProps, Input, TextArea } from "../components"

const FormField = styled(Box)`
  width: 100%;
  display: block;
`
const Label = styled.label`
  display: block;
  width: 100%;
`
const H4 = styled.h4`
  font-size: 1.8rem;
  width: 100%;
  display: block;
  margin-bottom: 0;
`
const P = styled.p`
  font-size: 0.8rem;
  width: 100%;
  display: block;
`

export const Quotation = (props: SectionProps) => {
  const [message, setMessage] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [sent, setSent] = useState(false)

  const submit = () => {
    console.log("skicka")
    console.log({
      message,
      email,
      phone,
    })
    setSent(true)
  }

  return (
    <Box
      id={props.id}
      // background="#ffffff"
      padding='1rem 0rem'
      colspan={12}
      maxWidth="1440px"
      margin="0 auto"
      breakpoints={[0, 568, 768, 1400]}
    >
      <Box
        cols={[12, 12, 6, 6]}
        display="flex"
        padding="0rem 2rem"
        alignItems="start"
        flexDirection="column"
      >
        {!sent && (
          <>
            <H4>Hör av dig till oss om frågor eller offert</H4>
            <FormField marginTop='1rem'>
              <Label htmlFor="email">Din e-post</Label>
              <Input
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormField>
            <FormField>
              <Label htmlFor="phone">Ditt telefonnummer</Label>
              <Input
                name="phone"
                id="phone"
                onChange={(e) => setPhone(e.target.value)}
              />
            </FormField>
            <FormField>
              <Label htmlFor="email">Meddelande</Label>
              <TextArea
                rows={10}
                cols={10}
                defaultValue={message}
                onChange={(e) => setMessage(e.target.value)}
              ></TextArea>
            </FormField>
            <FormField>
              <Link
                as="button"
                onClick={submit}
                target="_blank"
                rel="noopener noreferrer"
                className="button button-olive"
              >
                Skicka
              </Link>
            </FormField>
          </>
        )}
        {sent && (
          <Box
            alignSelf="center"
            height="100%"
            textAlign="center"
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <h4>Tack, skickat!</h4>
            <p>Vi återkommer till dig så snart som möjligt</p>
          </Box>
        )}
      </Box>
      <Box
        cols={[12, 12, 4, 6]}
        display="flex"
        alignItems="start"
        padding="2rem 2rem"
        justifyContent="start"
        flexDirection="column"

      >
        <H4>HAR DU SPECIELLA ÖNSKEMÅL ELLER FRÅGOR?</H4>
        <P>Tveka inte att höra av dig till oss så får du råd och tips.</P>
        <H4>ÄR NI BEHOV AV MÖTES- ELLER EVENTLOKAL?</H4>
        <P>
          Om ni köper maten av oss får ni hyra vår lokal på Kusthöjden utan
          extra kostnad. Lokalen rymmer upp till 100 sittande gäster
        </P>
      </Box>
    </Box>
  )
}
