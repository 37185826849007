import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import Box from "react-raster"
import { Heading, Link, SectionProps } from "./../components"

const ImgBox = styled(Box)`
  padding: 3rem 0rem;
  @media only screen and (max-width: 768px) {
    padding: 0rem;
    justify-content: left;
    margin-left: 2rem;
    display: none;
  }
`
const ImgBox2 = styled(Box)`
  padding: 3rem 0rem;
  display: none;
  @media only screen and (max-width: 768px) {
    padding: 0rem;
    justify-content: left;
    margin-left: 2rem;
    display: block;
  }
`

const Img = styled.img`
  display: block;
  max-width: 580px;
  max-height: 350px;
  width: auto;
  height: auto;
  @media only screen and (max-width: 1280px) {
    max-width: 480px;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 380px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 95%;
  }
`

export const Contact = (props: SectionProps) => {
  return (
    <Box
      id={props.id}
      // background="#fdfbfa"
      colspan={12}
      breakpoints={[0, 568, 768, 1400]}
      alignItems="start"
      maxWidth="1440px"
      margin="0 auto"
      justifyContent="left"
    >
      <Box
        cols={[12, 3, 3]}
        display="flex"
        padding="2rem"
        alignItems="left"
        flexDirection="column"
      >
        <h2>Kontakta Oss</h2>
        <p>
          <strong>Tel:</strong> 0611-201 22 eller 070-328 02 06
        </p>
        {/* <p>
          <strong>E-post:</strong> koket@freddesmat.se
        </p> */}
        <p>
          <strong>E-post:</strong> fredrik.ostrom@live.se
        </p>
        </Box>
        <Box
        cols={[12, 3, 3]}
        display="flex"
        padding="2rem 2rem"
        alignItems="left"
        flexDirection="column"
      >
        <h2>Besök oss</h2>
        <p>
          <strong>Öppettider</strong>
        </p>
        <p>Måndag - Fredag 10.30-13.00 för lunch.</p>
        <p>Övrig tid är det öppet för beställning.</p>
        <div style={{ marginTop: "1rem" }}></div>
        <p>
          <strong>Adress</strong>
        </p>
        <p>SÄLSTENSGRÄND 133</p>
        <p>871 30 HÄRNÖSAND</p>
        {/* <p>(Ka 5)</p> */}
      </Box>
      <ImgBox
        cols={[12, 6, 6]}
        display="flex"
        padding="2rem"
        alignItems="start"
        justifyContent="center"
      >
        <img
          src={require("./../images/karta.png").default}
          alt="Freddes Mat Map"
          width="550px"
        />
      </ImgBox>
    </Box>
  )
}
