import React, { useState } from "react"
import styled from "styled-components"
import Box from "react-raster"
import { useThemeContext } from "./../util"
import { useEffect } from "react"
// import logo from "../images/logo.png"
// const logo = require('./../images/logo.png');
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';


const LogoContainer = styled(Box)`
  justify-content: left;
  display: flex;
  margin-top: 5px;
`
const Logo = styled.img`
  width: 100px;
  height: 72px;
  @media (max-width: 768px) {
    width: 50;
    height: 36;
  }
`
const StyledCampaignImage = styled.img`
  width: 500px;
  height: auto;
  @media (max-width: 768px) {
    width: 90%
  }
`

const Wrapper = styled(Box)`
  z-index: 444;
  height: 6rem;
  position: fixed;
  top: 0;
  width: 100vw;
  align-content: space-between;
  justify-content: space-between;
  display: grid;
  flex-direction: row;
  padding: 0rem 2rem;
  @media (max-width: 768px) {
    padding: 0rem 1rem;
  }
`
const MenuWrapper = styled(Box)`
  display: flex;
  @media (max-width: 768px) {
    padding-top: 0rem;
  }
`
const Menu = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  margin-top: 1rem;
`
type MenuItemProps = {
  active?: boolean
}
const MenuItem = styled.li<MenuItemProps>`
  display: inline-flex;
  margin-right: 2rem;
  padding-bottom: 0.2rem;
  text-decoration: ${(props) => (props.active ? `underline` : "")};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
    /* border-bottom: 1px solid white; */
  }

  @media (max-width: 568px) {
    font-size: 0.85rem;
    margin-right: 0.9rem;
    /* margin-left: 0.5rem; */
  }
  &:last-child {
    margin-right: 0rem;
  }
`
const MenuItemLink = styled.a`
  color: white;
`

export const Navigation = () => {
  const { section, setSection } = useThemeContext()
  const [color, setColor] = useState("white");

  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  useEffect(() => {
    const isBrowser = typeof window !== "undefined";

    if(!isBrowser) return;
    window.addEventListener("scroll", (event) => {
      const e = document.getElementById("start")!
      const p = document.documentElement.scrollTop || document.body.scrollTop
      if (e.scrollHeight < p) {
        setColor("black")
      } else {
        setColor("white")
      }
    })
  }, [])

  const linkStyle = { color: color }
  
  return (
    <Wrapper colspan={2}>
      <LogoContainer cols={1}>
        <Logo
          src={require("./../images/Freddesmat_logo.png").default}
          alt="Freddes Mat logo"
        />
      </LogoContainer>
      <MenuWrapper cols={1}>
        <Menu>
          <MenuItem active={section === "start"} color={color}>
            <MenuItemLink
              onClick={(e) => setSection("start")}
              style={linkStyle}
            >
              Hem
            </MenuItemLink>
          </MenuItem>
          <MenuItem active={section === "lunch"} color={color}>
            <MenuItemLink
              onClick={(e) => setSection("lunch")}
              style={linkStyle}
            >
              Lunch
            </MenuItemLink>
          </MenuItem>
          <MenuItem active={section == "catering"} color={color}>
            <MenuItemLink
              onClick={(e) => setSection("catering")}
              style={linkStyle}
            >
              Catering
            </MenuItemLink>
          </MenuItem>
          <MenuItem active={section == "offert"} color={color}>
            <MenuItemLink
              onClick={(e) => setSection("offert")}
              style={linkStyle}
            >
              Offert
            </MenuItemLink>
          </MenuItem>
          <MenuItem active={section == "contact"} color={color}>
            <MenuItemLink
              onClick={(e) => setSection("contact")}
              style={linkStyle}
            >
              Kontakt
            </MenuItemLink>
          </MenuItem>
        </Menu>
      </MenuWrapper>
      {/* <Modal open={open} onClose={onCloseModal} center
        styles={{closeButton: {fill:'white', width: 40}}}
      >
        <StyledCampaignImage
          src={require("./../images/julbord_fredde_SKARP.jpg").default}
          alt="Freddes Mat logo"
          width='100px'
        />
      </Modal> */}
    </Wrapper>
  )
}
