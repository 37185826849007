import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import Box from "react-raster"
import { Heading, Link, SectionProps } from "./../components"
import { GatsbyImage } from "gatsby-plugin-image"
import { LunchmenyURL } from "../pages"


declare global {
  interface Window {
      _learnq: any;
  }
}
const ImgBox = styled(Box)`
  padding: 3rem 0rem;
  @media only screen and (max-width: 768px) {
    padding: 0rem;
    justify-content: left;
    margin-left: 2rem;
  }
`

const Img = styled.img`
  display: block;
  max-width: 530px;
  max-height: 325px;
  width: auto;
  height: auto;
  @media only screen and (max-width: 1024px) {
    max-height: 240px;
  }
  @media only screen and (max-width: 768px) {
    max-height: none;
    max-width: 95%;
  }
`

export const Lunch = (props: SectionProps) => {

  const [email, setEmail] = useState('jonas@limeloop.se')

  const onejan = new Date(new Date().getFullYear(), 0, 1)
  const week = Math.ceil(
    ((new Date().getTime() - onejan.getTime()) / 86400000 +
      onejan.getDay() +
      1) /
      7
  )

  const signup = () => {
    // Identify a user - create/update a profile in Klaviyo
    let _learnq = window._learnq || [];
    _learnq.push(['identify', {
      '$email': email,
    }]);
    track();
  }

  const track = () => {
    // Track a user's behavior - create an event in Klaviyo

    console.log('tracked?')
    let _learnq = window._learnq || [];
    _learnq.push(['identify', {
      '$email': email,
      'event': 'test-event'
    }]);
  }

  return (
    <StaticQuery
      query={graphql`
        query Section2Query {
          contentfulHomePage {
            lunchImage {
              gatsbyImageData (
                placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                )
            }
            lunchMenu {
              file {
                url
              }
            }
            lunchTitle
            lunchCopy {
              raw
            }
          }
        }
      `}
      render={(data) => {
        // Set ImageData.
        const { lunchCopy } = data.contentfulHomePage
        // console.log(data.contentfulHomePage.lunchImage)
        return (
          <Box
            id={props.id}
            background="#fdfbfa"
            colspan={12}
            breakpoints={[0, 568, 768, 1400]}
            alignItems="center"
            padding="1rem 0rem"
            justifyContent="space-between"
            maxWidth="1440px"
            margin="0 auto"
          >
            <Box
              cols={[12, 12, 6, 6]}
              display="flex"
              padding="2rem"
              alignItems="center">
              <Box display="flex" alignItems="left" flexDirection="column">
                <Heading>{data.contentfulHomePage.lunchTitle}</Heading>
                <Box>{lunchCopy && renderRichText(lunchCopy)}</Box>
                {/* <h2 style={{color: 'black', textDecoration: 'underline'}}>Semesterstängt, vi öppnar åter 1a augusti. Glad Sommar ☀️</h2> */}

                <Box marginTop="1rem">
                  <p>
                    <strong>Våra öppettider: </strong>
                  </p>
                  <p>Måndag - Fredag 10.30-13.00 för lunch.</p>
                  <p>Övrig tid är det öppet för beställning.</p>
                  <br />
                  <Link
                   href={data.contentfulHomePage.lunchMenu.file.url}
                    //  href={LunchmenyURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button button-olive"
                  >
                    Lunchmeny
                  </Link>
                  {/* <Link
                    href={'#'}
                    rel="noopener noreferrer"
                    className="button button-olive"
                    onClick={ e => signup()}
                  >
                    Sign up
                  </Link> */}
                </Box>
              </Box>
            </Box>
            <ImgBox
              cols={[12, 12, 6, 6]}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              {/* <Img src={data.contentfulHomePage.lunchImage.fluid.src} /> */}
              <GatsbyImage image={data.contentfulHomePage.lunchImage.gatsbyImageData} alt=""/>
            </ImgBox>
          </Box>
        )
      }}
    />
  )
}
