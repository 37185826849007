import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import Box from "react-raster"
import { Link, SectionProps } from "./../components"
import { LunchmenyURL } from "../pages"

type FullscreenBoxProps = {
  image?: string
}

const FullscreenBox = styled(Box)<FullscreenBoxProps>`
  background-image: ${(props: any) =>
  props.image ? `url(${props.image})` : ""};
  width: 100vw;
  height: 100vh;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
`

const FullscreenBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  margin-left: 2rem;
  max-width: 50vw;
  @media (max-width: 768px) {
    max-width: 90vw;

  }

`
const Heading = styled.h1`
  color: white;
  line-height: 1;
  text-transform: uppercase;
  font-size: 6vw;
  margin-bottom: 0;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 10vw;
  }
`

const Subheading = styled.p`
  font-size: 1.5vw;
  color: white;
  max-width: 75vw;
  font-weight: 300;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 2vw;
  }
  @media (max-width: 568px) {
    font-size: 3vw;
  }
`

export const Hero = (props: SectionProps) => (
  <StaticQuery
    query={graphql`
      query {
        contentfulHomePage {
          heroImage {
            file{url}
          }
          heroTitle
          heroSubtitle
          lunchMenu {
            file {
              url
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.contentfulHomePage.heroImage.file.url
 /* replaceAll(
  ".",
  ".<br />"
), */
      return (
        <FullscreenBox image={imageData} id={props.id}>
          <FullscreenBoxContent>
            <Heading
              dangerouslySetInnerHTML={{
                __html: data.contentfulHomePage.heroTitle,
              }}
            ></Heading>
            <Subheading
              dangerouslySetInnerHTML={{
                __html: data.contentfulHomePage.heroSubtitle
              }}
            ></Subheading>
            <Link target='_blank' 
             href={data.contentfulHomePage.lunchMenu.file.url}
              //  href={LunchmenyURL}
          >Lunchmeny</Link>
            {/* href={'https://assets.ctfassets.net/4tspwvume9yz/5b5gLzIQfFetJZyJQIDjMz/ab510e888811982b437f69238da45f03/Lunchmeny__2024_17_Pdf.pdf'} */}

            {/* <h2 style={{color: 'white', textDecoration: 'underline'}}>Semesterstängt, vi öppnar åter 1a augusti. Glad Sommar ☀️</h2> */}
          </FullscreenBoxContent>
        </FullscreenBox>
      )
    }}
  />
)
