import React, { useEffect, createContext } from "react"

type Section = "start" | "lunch" | 'catering' | 'offert' | 'contact'

export type ThemeContextType = {
  section: Section
  setSection: (s: Section) => void
}

export const ThemeContext = createContext<ThemeContextType>({
  section: 'start',
  setSection: () => {},
})

type ThemeContextProviderProps = {
  children?: React.ReactNode
}

export const ThemeContextProvider = (props: ThemeContextProviderProps) => {
  const [section, setSection] = React.useState<Section>('start');
  useEffect( () => {
    const element = document.getElementById(section)
    if(element) {
      element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
  }, [section]);
  return (
    <ThemeContext.Provider
      value={{
        section: section,
        setSection: setSection,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  )
}

export const useThemeContext = () => React.useContext(ThemeContext)
