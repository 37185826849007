import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import Box from "react-raster"
import { Heading, Link, SectionProps } from "./../components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ImgBox = styled(Box)`
  padding: 3rem 0rem 0rem 2rem;
  @media only screen and (max-width: 768px) {
    padding: 0rem;
    justify-content: left;
    margin-left: 2rem;
    display: none;
  }
`
const ImgBox2 = styled(Box)`
  padding: 3rem 0rem;
  display: none;
  @media only screen and (max-width: 768px) {
    padding: 0rem;
    justify-content: left;
    margin-left: 2rem;
    display: block;
  }
`

const Img = styled.img`
  display: block;
  max-width: 580px;
  max-height: 350px;
  width: auto;
  height: auto;
  @media only screen and (max-width: 1280px) {
    max-width: 480px;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 380px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 95%;
  }
`

export const Catering = (props: SectionProps) => {
  return (
    <StaticQuery
      query={graphql`
        query CateringQuery {
          contentfulHomePage {
            cateringImage {
              gatsbyImageData (
               
                placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                )
            }
            cateringMenu {
              file {
                url
              }
            }
            cateringTitle
            cateringCopy {
              raw
            }
          }
        }
      `}
      render={(data) => {

        // Set ImageData.
        const { cateringCopy } = data.contentfulHomePage;
        
        return (
          <Box
            id={props.id}
            background="#fdfbfa"
            colspan={12}
            display='flex'
            breakpoints={[0, 568, 768, 1400]}
            alignItems="center"
            justifyContent="space-between"
            padding='1rem 0rem'
            maxWidth="1440px"
            margin="0 auto"
            >
            <ImgBox
              cols={[12, 12, 6, 6]}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              {/* <Img src={data.contentfulHomePage.cateringImage.fluid.src} /> */}
            {/* <GatsbyImage image={image} alt={""}/> */}
            <GatsbyImage image={data.contentfulHomePage.cateringImage.gatsbyImageData} alt=""/>
            </ImgBox>
            <Box
              cols={[12, 12, 6, 6]}
              display="flex"
              padding="2rem"
              alignItems="center"
            >
              <Box display="flex" alignItems="left" flexDirection="column">
                <Box>
                  <Heading>{data.contentfulHomePage.cateringTitle}</Heading>
                  {cateringCopy && renderRichText(cateringCopy)}
                  <br />
                  <strong>Kontakta oss för pris/offert eller beställa catering</strong>
                  <p>
                    <strong>Tel:</strong> 0611-201 22 eller 070-328 02 06
                  </p>
                  {/* <p>
                    <strong>E-post:</strong> koket@freddesmat.se
                  </p> */}
                  <p>
                    <strong>E-post:</strong> fredrik.ostrom@live.se
                  </p>
                  {/* <Link
                    href={data.contentfulHomePage.cateringMenu.file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button button-olive"
                  >
                    CATERINGMENY
                  </Link> */}
                </Box>
              </Box>
            </Box>
            <ImgBox2
              cols={[12, 12, 6, 6]}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              flexDirection="column"

            >
              {/* <Img src={data.contentfulHomePage.cateringImage.fluid.src} /> */}
              <GatsbyImage image={data.contentfulHomePage.cateringImage.gatsbyImageData} alt=""/>
            </ImgBox2>
          </Box>
        )
      }}
    />
  )
}
