import React from "react"
import styled from "styled-components"
import Box from "react-raster"


export type SectionProps = {
  id?: string
}

export const Heading = styled.h2`
  display: flex;
  flex: 0 0 100%;
  font-size: 3.5rem;
  margin-bottom: 0.5rem;
  @media (max-width: 968px) {
    font-size: 3rem;
  }
  @media (max-width: 568px) {
    margin: 1rem 0rem;
  }
`

export const Link = styled.a`
  background-color: #a5a34e; /* Green */
  border: none;
  color: white;
  padding: 0.8rem 1.2rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  max-width: 6rem;
  cursor: pointer;
  @media (max-width: 568px) {
    /* width: 120px; */
    font-size: 0.8rem;
  }
`

export const Input = styled.input`
  font-size: 18px;
  padding: 10px;
  margin: 10px 0px;
  width: 90%;
  background: #d1d1d1;
  border: none;
  border-radius: 3px;
  ::placeholder {
    color: palevioletred;
  }
`;

export const TextArea = styled.textarea`
  font-size: 18px;
  padding: 10px;
  margin: 10px 0px;
  background: #d1d1d1;
  border: none;
  border-radius: 3px;
  width: 90%;
  height: 100%;
  ::placeholder {
    color: palevioletred;
  }
`;
