import React from "react"
import Box from "react-raster"

export const Footer = () => (
  <Box width="100%" padding='2rem' justifyContent='center'>
    <footer style={{textAlign: 'center'}}>
      <small>Copyright Freddes Mat &copy; {new Date().getFullYear()} utvecklad av Limeloop AB</small>
    </footer>
  </Box>
)
