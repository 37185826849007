import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import Box from "react-raster"
import { Heading, Link, SectionProps } from "./../components"

const ImgBox = styled(Box)`
  padding: 3rem 0rem 0rem 2rem;
  @media only screen and (max-width: 768px) {
    padding: 0rem;
    justify-content: left;
    margin-left: 2rem;
    display: none;
  }
`
const ImgBox2 = styled(Box)`
  padding: 3rem 0rem;
  display: none;
  @media only screen and (max-width: 768px) {
    padding: 0rem;
    justify-content: left;
    margin-left: 2rem;
    display: block;
  }
`

const Img = styled.img`
  display: block;
  max-width: 580px;
  max-height: 350px;
  width: auto;
  height: auto;
  @media only screen and (max-width: 1280px) {
    max-width: 480px;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 380px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 95%;
  }
`

export const Campaign = (props: SectionProps) => {
  return (
    <StaticQuery
      query={graphql`
        query newYearQuery {
          contentfulHomePage {
            julMeny {
              file {
                url
              }
            }
            nyarMeny {
              file {
                url
              }
            }
            julImage {
              file {
                url
              }
            }
          }
        }
      `}
      render={(data) => {
        // Set ImageData.
        const { julMeny, julImage, nyarMeny } = data.contentfulHomePage

        // const currentURL = typeof window != undefined ? window.location.href : 'http://localhost';

        // // Create a URL object
        // const url = new URL(currentURL);

        // // Access the search params
        // const params = url.searchParams;

        // Check if 'show_ad' param is present
        // if (!params.has('show_ad')) return null

        return (
          <Box
            id={props.id}
            background="#fdfbfa"
            colspan={12}
            display="flex"
            breakpoints={[0, 568, 768, 1400]}
            alignItems="left"
            justifyContent="left"
            // padding="1rem 0rem"
            columnGap='6rem'
            maxWidth="1440px"
            margin="0 auto"
          >
            <ImgBox
              cols={[12, 12, 6, 6]}
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Img src={data.contentfulHomePage.julImage.file.url} />
            </ImgBox>
            <Box
              cols={[12, 12, 6, 6]}
              display="flex"
              padding="2rem"
              alignItems="center"
            >
              <Box display="flex" alignItems="left" flexDirection="column">
                <Box>
                  <Heading>Vi närmar oss jul och nyår!</Heading>
                  <strong>Beställningar gör du till:</strong>
                  <p>Tel: 0611-201 22 eller 070-328 02 06</p>
                  <p>E-post: fredrik.ostrom@live.se</p>
                  {/* <p>Alt. e-post: fredrik.ostrom@live.se</p> */}
                  {/* <h2 style={{marginTop: 25}}>Vill du redan nu veta mer om årets julmeny samt nyårsmeny? <br />Ring eller maila till Fredde.</h2>
                  <p style={{marginTop: 15}}>Årets menyer kommer även att publiceras här på hemsidan inom kort.</p> */}


                  <p>Ladda ner vår julmeny här</p>
                  <Link
                    target="_blank"
                    href={data.contentfulHomePage.julMeny.file.url}
                  >
                    Julbord 2024
                  </Link>
                  <div style={{margin: 20, width: '100%'}} />
                  <Link
                    target="_blank"
                    href={data.contentfulHomePage.nyarMeny.file.url}
                  >
                    Nyår 2024
                  </Link> 
                </Box>
              </Box>
            </Box>
          </Box>
        )
      }}
    />
  )
}
